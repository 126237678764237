:root {
  --color-primary: #191919;
  --color-secondary: #f3f3f3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Background */
  background: url(https://dummyimage.com/1920x1080/000/fff) no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hover-effect:hover,
.hover-effect:hover a {
  background-color: var(--color-primary);
  color: white;
  transition: background-color 0.1s ease-in-out;
}

a {
  text-decoration: none;
  color: unset;
}
